export default {
  namespaced: true,
  state: {
    userInfo: null,
    currentYear: '2022',
  },
  getters: {},
  mutations: {
    SET_USER(state, val) {
      state.userInfo = val
    },
    UPDATE_USER_NAME(state, val) {
      if (state.userInfo) {
        state.userInfo.name = val
      }
    },
    UPDATE_USER_PHONE(state, val) {
      if (state.userInfo) {
        state.userInfo.phone = val
      }
    },
  },
  actions: {},
}
