import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

// Firebase
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/functions'
import 'firebase/analytics'

// Vuefire
import { rtdbPlugin } from 'vuefire'

import ToastificationContent from 'vue-toastification'

import { initialAbility } from '@/libs/acl/config'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// Data services
import auth from './services/auth'

// ACL
import '@/libs/acl'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASSUREMENT_ID,
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

// Vuefire registration
Vue.use(rtdbPlugin)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

if (process.env.NODE_ENV !== 'production') {
  firebase.database().useEmulator('localhost', 9000)
  firebase.auth().useEmulator('http://localhost:9099')
  firebase.storage().useEmulator('localhost', 9199)
  firebase.functions().useEmulator('localhost', 5001)
} else {
  firebase.analytics()
}

let app

firebase.auth().onAuthStateChanged(async user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app')
  }
  if (!user) {
    // required for ACL
    localStorage.removeItem('userData')
    // Reset ability
    app.$ability.update(initialAbility)
    app.$store.commit('services/SET_USER', null)
  } else {
    try {
      const userInfo = await auth.getCurrentUserInfo()
      if (!userInfo) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Usuario no configurado',
            text: 'Por favor, comuníquese con el administrador del sistema',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      }
      userInfo.ability = []
      if (userInfo.type === 'Cliente') {
        userInfo.ability.push({ subject: 'Home', action: 'read' })
      } else if (userInfo.type === 'Gerencia') {
        userInfo.ability.push({ subject: 'Home', action: 'read' })
        userInfo.ability.push({ subject: 'AllOrganizations', action: 'read' })
        userInfo.ability.push({ subject: 'System', action: 'manage' })
      } else if (userInfo.type === 'Asesor') {
        userInfo.ability.push({ subject: 'Home', action: 'read' })
        userInfo.ability.push({ subject: 'AllOrganizations', action: 'read' })
      } else if (userInfo.type === 'ClienteRed') {
        userInfo.ability.push({ subject: 'Home', action: 'read' })
        userInfo.ability.push({ subject: 'MyNetworkBenchmarks', action: 'read' })
        userInfo.ability.push({ subject: 'AllOrganizations', action: 'read' })
      }
      app.$store.commit('services/SET_USER', userInfo)
      app.$ability.update(userInfo.ability)
      localStorage.setItem('userData', JSON.stringify(userInfo))
    } catch (err) {
      // console.error(err)
    }
  }
})
