// Firebase
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

const reauthenticate = currentPassword => {
  const user = firebase.auth().currentUser
  const cred = firebase.auth.EmailAuthProvider.credential(
    user.email, currentPassword,
  )
  return user.reauthenticateWithCredential(cred)
}

export default {
  async getCurrentUserInfo() {
    const snapshot = await firebase.database().ref('users').child(firebase.auth().currentUser.uid).once('value')
    if (snapshot.exists()) {
      const returnValue = snapshot.val()
      returnValue.uid = firebase.auth().currentUser.uid
      return returnValue
    }
    return null
  },
  async changePassword(currentPassword, newPassword) {
    await reauthenticate(currentPassword).then(async () => {
      const user = firebase.auth().currentUser
      await user.updatePassword(newPassword)
    })
  },
}
