import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/app'
import 'firebase/auth'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Inicio',
        breadcrumb: [
          {
            text: 'Inicio',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/networks/mynetwork/benchmarks',
      name: 'my-network-benchmarks',
      component: () => import('@/views/networks/MyNetworkBenchmarks.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/organizations',
      name: 'organizations',
      component: () => import('@/views/organizations/OrganizationList.vue'),
      meta: {
        pageTitle: 'Organizaciones',
        breadcrumb: [
          {
            text: 'Organizaciones',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/organizations/:id',
      name: 'organizations-view',
      component: () => import('@/views/organizations/Organization.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/organizations/:id/indicators',
      name: 'organizations-view-indicators',
      component: () => import('@/views/organizations/OrganizationIndicators.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/admin/organizations',
      name: 'admin-organizations',
      component: () => import('@/views/admin/organizations/OrganizationList.vue'),
      meta: {
        pageTitle: 'Instituciones',
        breadcrumb: [
          {
            text: 'Admin de Sistema',
            active: true,
          },
          {
            text: 'Instituciones',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/admin/organizations/create',
      name: 'admin-organizations-create',
      component: () => import('@/views/admin/organizations/OrganizationCreate.vue'),
      meta: {
        pageTitle: 'Nueva Institución',
        breadcrumb: [
          {
            text: 'Admin de Sistema',
            active: true,
          },
          {
            text: 'Instituciones',
            to: { name: 'admin-organizations' },
          },
          {
            text: 'Nueva',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/admin/organizations/:id',
      name: 'admin-organizations-edit',
      component: () => import('@/views/admin/organizations/OrganizationEdit.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/admin/networks',
      name: 'admin-networks',
      component: () => import('@/views/admin/networks/NetworkList.vue'),
      meta: {
        pageTitle: 'Redes',
        breadcrumb: [
          {
            text: 'Admin de Sistema',
            active: true,
          },
          {
            text: 'Redes',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/admin/networks/:id/organizations',
      name: 'admin-networks-organizations',
      component: () => import('@/views/admin/networks/NetworkOrganizationList.vue'),
      meta: {
        pageTitle: 'Organizaciones de la Red',
        breadcrumb: [
          {
            text: 'Admin de Sistema',
            active: true,
          },
          {
            text: 'Redes',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/admin/users',
      name: 'admin-users',
      component: () => import('@/views/admin/users/UserList.vue'),
      meta: {
        pageTitle: 'Usuarios',
        breadcrumb: [
          {
            text: 'Admin de Sistema',
            active: true,
          },
          {
            text: 'Usuarios',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/admin/users/create',
      name: 'admin-users-create',
      component: () => import('@/views/admin/users/UserCreate.vue'),
      meta: {
        pageTitle: 'Nuevo Usuario',
        breadcrumb: [
          {
            text: 'Admin de Sistema',
            active: true,
          },
          {
            text: 'Usuarios',
            to: { name: 'admin-users' },
          },
          {
            text: 'Nuevo',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/admin/users/:id',
      name: 'admin-users-edit',
      component: () => import('@/views/admin/users/UserEdit.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgotpassword',
      name: 'auth-forgot-password',
      component: () => import('@/views/auth/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/user/profile/edit',
      name: 'user-profile-edit',
      component: () => import('@/views/user/profile/EditUserProfile.vue'),
      meta: {
        pageTitle: 'Actualizar Información de Usuario',
        breadcrumb: [
          {
            text: 'Perfil de Usuario',
            active: true,
          },
          {
            text: 'Editar',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/changepassword',
      name: 'auth-change-password',
      component: () => import('@/views/auth/ChangePassword.vue'),
      meta: {
        pageTitle: 'Cambiar contraseña',
        breadcrumb: [
          {
            text: 'Usuario',
            active: true,
          },
          {
            text: 'Cambiar contraseña',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isAuthenticated = firebase.auth().currentUser
  if (requiresAuth && !isAuthenticated) {
    next('/login')
  } else {
    next()
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
